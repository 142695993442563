import React from 'react';
import { Link } from 'gatsby';


import * as styles from './Footer.module.css';

const Footer = () => {

  return (
    <footer className={styles.footer}>
      <div className={styles.copyright}>
        <p className="copy copy--sm text-purple">&copy; Medicus Economics - All Rights Reserved.</p>
      </div>
      <div className={styles.nav}>
        <ul className="flex flex-col sm:flex-row items-center gap-lg md:gap-xl">
          <li>
            <Link className={styles.link} to="/contact">Contact</Link>
          </li>
          <li>
            <Link className={styles.link} to="/team">Team</Link>
          </li>
          <li>
            <Link className={styles.link} to="/careers">Careers</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;